<template>
  <v-container fluid>
    <div v-if="this.$route.name === 'rules'">
      <v-btn class="ma-3" color="accent" small :to="`/survey/details/${id}/rules/new-rule`">
        <v-icon left dark>mdi-plus</v-icon>new rule
      </v-btn>
      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="rules"
        :search="search"
      >
        <template v-slot:item.name="{ item }">
          <a @click="resp(item.id)">{{ item.name }}</a>
        </template>
      </v-data-table>
    </div>
    <div v-else-if="this.$route.name ===`new-rule`">
      <router-view />
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      id: null,
      search: null,
      rules: [],
      choices: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Images", value: "image" },
        { text: "Answer Type", value: "questiontype" },
        { text: "Possible Answer", value: "choice.name" }
      ]
    };
  },
  watch: {
    "$route.params.id": {
      handler: "setId",
      immediate: true
    }
  },
  methods: {
    getRules() {
      const self = this;
      this.loading = true;
      const id = this.$route.params.id;
      this.$store
        .dispatch("get", `/getrules/${id}`)
        .then(res => {
          console.log(res, "r");
          self.rules = res;
          self.choices = res.options;
          console.log(self.choices, "options");
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    resp(id) {
      this.$router.push(`/responses/${id}`);
    },
    setId(id) {
      this.id = id;
    }
  },
  created() {
    this.getRules();
  }
};
</script>